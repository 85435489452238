<template>
  <router-link
      :to="film.href"
      :class="{
        'dense':dense,
        'normal':!dense,
        'is-long-title':film.isLongName
      }"
      class="film-item">
    <v-responsive :aspect-ratio="imgRatio">
      <img :src="imgSrc"
           :alt="film.thumbnail.alt"/>
    </v-responsive>
    <div class="texts">
      <h2 >{{ film.name }}</h2>
      <h3>{{ film.unfilmde }}</h3>
      <p v-if="film.textposter" class="ty-rich-text mb-0">{{ film.textposter }}</p>
      <div class="decouvrir text-no-wrap ty-link">
        <span class="ty-title-link">-></span> Découvrir
      </div>
    </div>

  </router-link>
</template>

<script>
import recordMixin from "@/ee09/db-ui/records/RecordMixin";

export default {
  name: "film-item",
  mixins:[recordMixin],
  props:{
    dense:{
      type:Boolean
    }
  },
  computed:{
    /**
     *
     * @return {PagefilmModel}
     */
    film(){
      return this.record;
    },
    isLarge(){
      return this.$vuetify.breakpoint.smAndUp
    },
    imgRatio(){
      if(this.isLarge && this.dense){
        return 1.3;
      }
      return 2;
    },
    imgSrc(){
      return this.film.thumbnail.resize(
          1200,1200,'contain',"111111",80,"webp"
      )
    }
  }
}
</script>

<style lang="less">
//typographie et couleur
.film-item{

  --heading-size:var(--s1);
  &.is-long-title{
    --heading-size:20px;
  }
  --gutter:30px;
  --ico-size:20px;
  @media(min-width: @screen-sm){
    --heading-size:var(--s1);
    --gutter:var(--heading-size);
    --ico-size:30px;
  }
  @media(min-width: @screen-lg){
    --heading-size:100px;
    --gutter:var(--heading-size);
    --ico-size:50px;
  }

  @media(min-width: @screen-sm){
    &.dense{
      @media(min-width: @screen-sm){
        --heading-size:25px;
        &.is-long-title{
          --heading-size:20px;
        }
        --gutter:var(--heading-size);
        --ico-size:30px;
      }
      @media(min-width: @screen-md){
        --heading-size:40px;
        &.is-long-title{
          --heading-size:30px;
        }
      }
      @media(min-width: @screen-lg){
        --heading-size:70px;
        --gutter:var(--heading-size);
        --ico-size:50px;
        &.is-long-title{
          --heading-size:40px;
        }
      }
    }
  }



  text-decoration: none;
  font-family: var(--font-title);
  color: #fff;




  h2{
    color: var(--color-project);
    font-size: var(--heading-size);

    text-transform: uppercase;
    //font-style: italic;
    transform: skewX(-9deg);
    line-height: 0.9;
    letter-spacing: -0.07em;
    font-weight: 900;
  }
  h3{
    color: #fff;
    font-size: var(--s3);
  }
  .decouvrir{
    display: flex;
    align-items: center;
    span{
      font-size: var(--ico-size);
      margin-right: var(--ico-size);
    };
  }
}
//positions
.film-item{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  .v-responsive{
    flex-grow: 0;
    flex-shrink: 0;
  }
  .texts{
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    //outline: 1px solid red;
    margin-left: var(--gutter);

    h2{
      //outline: 1px solid pink;
      position: absolute;
      bottom: calc( 100% - 0.5 * var(--heading-size));
      width: calc(100% - var(--gutter) * 1);
      @media(min-width: @screen-lg){
        width: calc(100% - var(--gutter) / 2  );
      }

    }
    padding-top: calc( 0.6 * var(--heading-size));
    .decouvrir{
      margin-top: auto;
    }
  }
  &.normal{
    .decouvrir{
      @media(min-width: @screen-lg){
        justify-content: flex-end;
        position: absolute;
        right: 0;
        bottom: -20px;
      }
    }
  }

  //image
  .v-responsive{
  }
  .v-responsive__content{

    >*{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:after{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.0) 100%);
    }
  }

  //hover
  img,h2{
    transition: all 0.2s;
  }
  @media(hover: hover){
    &:hover{
      img{
        filter: brightness(0.8);
      }
      h2{
        transform: skewX(0deg);
        font-weight: 400;
      }
      .decouvrir{
        span{
          animation: arrow 0.7s alternate infinite;
          @keyframes arrow{
            from{
              transform: translateX(0);
            }
            to{
              transform: translateX(10px);
            }
          }
        }
      }
    }
  }
}
</style>